.network-sim {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.network-btn{
    padding: 10px 20px 10px 20px;
    background-color: #810b07;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}