iframe {
    height: 600px;
    width:100%;
}

@media (max-width:767px) {
    iframe {
        height: 600px;
        width: 100%;
    }
}
