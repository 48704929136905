.expt-template {
    padding-left: 30px;
    padding-top: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    background-color: #f1f1f1;
    text-align: justify;
}

.expt-heading {
    margin-bottom: 30px;
}

ul,
ol {
    list-style: none;
}

.lab-manual-heading {
    font-weight: 600;
}