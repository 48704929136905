.site-footer {
    background-color: #ffffff;
    padding: 45px 0 20px 0;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.701);
    width: 100%;
}

.site-footer h6 {
    color: rgba(0, 0, 0, 0.701);
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px
}

.site-footer a {
    color: rgba(2, 2, 2, 0.701);
    text-decoration: none;
}

.site-footer a:hover {
    color: rgba(0, 0, 0, 0.701);
    text-decoration: none;
}

.footer-links {
    padding-left: 0;
    /* padding: 0px 45 0px 45; */
    list-style: none
}

.site-footer #second-row {
    width: 100%;
}

.footer-links li {
    display: block
}

.footer-links a {
    color: rgba(129, 11, 7, 0.701);
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
    color: rgba(96, 20, 15, 0.785);
    text-decoration: none;
}

.footer-links.inline li {
    display: inline-block
}

.copyright-text {
    margin-top: 20px;
    text-align: center;
}

@media (max-width:991px) {
    .site-footer [class^=col-] {
        margin-bottom: 30px;
        overflow-x: hidden;
    }

}

@media (max-width:767px) {
    .site-footer {
        overflow-x: hidden;
    }

    .site-footer,
    .site-footer #quick-links {
        text-align: center;
        padding: 0;
    }

}

.row {
    display: flex;
    justify-content: space-between;
}

#quick-links {
    text-align: left;
}

/* .web {
    width: 40px;
    margin: 10px;
} */