.nav-bar {
  padding-left: 20px;
  padding-right: 20px;
  /* position: sticky; */
}

.bg-blueBg {
  background-color: #810b07;
}

.nav-logo-img {
  width: 350px;
  padding: 0;
}

.nav-link {
  margin: 5px;
  font-size: large;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: end;
  }
}