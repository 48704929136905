.projects{
    padding-left: 50px;
    padding-top: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    background-color: #f1f1f1;
    text-align: justify;
}
.projects-l {
    list-style-type: circle;
}